import { useRef, useEffect, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { Link, Element } from 'react-scroll';

function App() {
  const elementRef = useRef(null);
  const [elementHeight, setElementHeight] = useState(0);
  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setElementHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const [isSend, setIsSend] = useState(false)
  const handleForm = (e) => {
    e.preventDefault();
    setIsSend(true);

  }

  const deals = [
    {
      but: "Assorted",
      img: "/Cut_Offs.jpg",
      title: "Cut Offs, Assorted",
      text1: "Looking for small pieces of wood for crafting projects? You've landed on the right page! This material is ideal for very small projects like jewelry, inlay, intarsia, and for gluing up.",
      text2: "Sometimes you'll find half pen blanks, and in certain species you'll find wood large enough for small knobs or knife handles. When you don't need a whole board, but you'd like to accent your project or try out a new exotic wood species, this is a great choice."
    },
    {
      but: "Turning Wood",
      img: "/Bannerturning9.jpg",
      title: "Turning Wood",
      text1: "Woodturning on the lathe, an ancient and prized art, has long been a therapeutic outlet for woodworkers. Whether you're an expert or you're just getting started as a woodturner, we understand that you want to start with high quality turning wood!",
      text2: "That's why we carefully select exotic and domestic wood blanks that range in size, price, and figure to meet the needs of each of our woodworking customers."
    },
    {
      but: "Knife Blanks",
      img: "/Bannerknifeblanks.jpg",
      title: "Knife Blanks",
      text1: "Custom, handmade wood knives lend character and a personal touch to a tool that has been a staple for thousands of years.",
      text2: "Cook Woods provides many choices in hardwood knife blanks! Attractive grain and durability are critical in custom knives, so we provide knife scales and knife blanks in many exotic woods, cut to precision to maximize yield, all making the process easy for you!"

    }
  ]

  const gallery = [
    {
      img: "/Swiss_Pear_Craft_Blank_1.jpeg",
      title: "Swiss Pear Knife Blank",
      price: "$55.41 X 1",
      text: "An elegant fruitwood from Northern Europe, Pear wood has fine, closed grain and is easy to work. Warm, rich pinkish/red colors give a classic, beautiful look to your project! This knife blank is kiln dried and ready to use.d"
    },
    {
      img: "/East_Indian_Rosewood_Bowl_Blanks.jpeg",
      title: "East Indian Rosewood Bowl",
      price: "$60.98 X 1",
      text: "East Indian Rosewood is set apart from many woods through its range of colors. Each piece is unique, with any combination of black, violet, brown, purple, olive green, and blue undertones. Air dried for a year, these bowl blanks contain residual moisture."
    },
    {
      img: "/Spalted_Tamarind_Peppermill.jpeg",
      title: "Spalted Tamarind Peppermill",
      price: "$49.21",
      text: "Spalted Tamarind is from Southeast Asia and has unique, black spalted color contrast. Spalted areas can benefit from CA glue if softer areas are present. An occasional check and/or pin/bug holes may be present."
    },
    {
      img: "/158372_000_001_1946x.jpeg",
      title: "Canarywood Wood Turning",
      price: "$42.23 X 1",
      text: "Canarywood is a uniquely beautiful wood that parades a prism of natural colors from bright yellow, orange, red and purple. The wood is easy enough to work with hand and power tools, and it’s medium-fine texture lets you give your project a perfectly smooth finish."
    },
    {
      img: "/131138_000_001_493x.jpeg",
      title: "Baltic Birch Plywood",
      price: "$33.02 X 1",
      text: " Baltic Birch is the plywood of choice for a number of uses because it's inexpensive, stable, holds screws exceptionally well, and it's made with waterproof glue. Use Baltic Birch for your box bottoms, drawer sides, scroll saw art, shop jig-making and other craft work. "
    },
    {
      img: "/188155_000_001_1946x.jpeg",
      title: "Purpleheart",
      price: "$47.82 X 1",
      text: "INCLUDES 1 WOOD BLANK (several blanks are shown to indicate the range of color/grain to expect). Purpleheart, Peltogyne spp., is one of the most colorful species on the Earth.  The heartwood is vivid purple when exposed to UV light."
    }
  ]


  return (
    <div className="min-h-screen flex flex-col">

      <div className="h-[100px] flex items-center justify-center text-gray-800">
        <div className="flex flex-row justify-between items-center w-full mx-8 max-w-[1000px]">
          <h1 className="text-3xl font-bold">ClairWoods</h1>
          <div className="space-x-10 text-lg font-semibold">
            <Link to="section1" className="hover:text-orange-600 text-orange-600 cursor-pointer" smooth={true} duration={500}>BEST DEAL</Link>
            <Link to="section2" className="hover:text-orange-600 cursor-pointer" smooth={true} duration={500}>Properties</Link>
            <Link to="section3" className="hover:text-orange-600 cursor-pointer" smooth={true} duration={500}>Contact Us</Link>
          </div>
        </div>
      </div>

      <div className="min-h-[450px] bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: "url('/main.jpg')" }}>
        <div className="w-full max-w-[1000px] px-[60px]">
          <span className="text-white font-bold text-[60px]">Great Wood<br></br> & Great Customer <br></br>Service!</span>
        </div>
      </div>


      <div className="flex items-center justify-center bg-gray-100 py-10">
        <div className="flex flex-col w-full max-w-[1000px] mx-8 space-y-8">

          <div className="flex md:flex-row md:items-end justify-between space-y-6 flex-col items-center" name="section1">

            <div className="flex flex-col space-y-4 md:items-start items-center">
              <span className="text-red-400 font-semibold text-md">| BEST DEAL</span>
              <h2 className="font-bold text-3xl text-gray-800 text-gray-900 md:max-w-[350px] text-center md:text-start">
                Find Your Best Deal Right Now!
              </h2>
            </div>

            <div className="flex flex-row space-x-4 [&>button]:but1 text-white">
              {
                deals.map((deal, d) => (
                  <button key={d} onClick={() => setIndex(d)} className={d == index ? '!bg-orange-600' : ''}>{deal.but}</button>
                ))
              }
            </div>

          </div>

          <div className="flex flex-row justify-center space-x-10" >
            {/* <div className="mx-auto md:mx-0">
              <div className="card shadow-md border px-6 py-3 w-full">
                {
                  [1,2,3,4,5].map((item, i) => (
                    <div key={i} className={`flex flex-row items-center justify-between space-x-12 md:space-x-6 py-3 ${i !== 4 ? 'border-b' : ''}`}>
                      <span className="text-gray-400 text-sm">Floor number</span>
                      <span className="font-bold">26th</span>
                    </div>
                  ))
                }
              </div>
            </div> */}
            <div className="flex items-center justify-center ">
              <img src={`/images/${deals[index].img}`} className="h-[320px]" />
            </div>
            <div className="flex flex-col space-y-4 max-w-[300px] ">
              <h2 className="font-semibold">{deals[index].title}</h2>
              <p className="text-md leading-snug">{deals[index].text1}</p>
              <p className="text-md leading-snug">{deals[index].text2}</p>
            </div>
          </div>

        </div>
      </div>


      <div className="my-10 text-center space-y-5" name="section2">
        <span className="text-red-400 font-semibold text-md">| Properties</span>
        <p className="font-bold text-3xl text-gray-800">We Provide<br></br>The Best Property</p>
      </div>


      <div className="mb-[80px] flex items-center justify-center">
        <div className="flex items-center justify-center w-full max-w-[1000px] mx-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
            {
              gallery.map((item, i) => (
                <div key={i} className="flex flex-col card p-[24px]">
                  <img src={`/images/${item.img}`} className="rounded-lg mb-[24px]" />
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row justify-between">
                      <span className="bg-red-200 px-2 rounded-md text-sm">Luxury Wood</span>
                      <span className="text-orange-600 font-bold">{item["price"]}</span>
                    </div>
                    <h2 className="font-semibold">{item['title']}</h2>
                    <p className="text-sm text-gray-600">{item['text']}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className="mb-10" name="section3" style={{ position: 'relative' }}>
        <div style={{ zIndex: -1, backgroundImage: "url('/images/Bannerslabsunder6feet2.jpeg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%', height: '70%', position: 'absolute', top: 0, left: 0 }}></div>
       
        <div className="my-10 text-center space-y-5">
          <span className="text-red-500 font-semibold text-md">| Contact Us</span>
          <p className="font-bold text-3xl text-white">Get In Touch<br></br>With Our Agents</p>
        </div>

        <div className="flex justify-center items-center">
          <div className="grid grid-cols-7 lg:grid-cols-12 gap-10 w-full mx-8 max-w-[1000px]">
            <div className="col-span-7 flex flex-col space-y-8">
              <div className="card p-0 border-0">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12469.776493332698!2d-80.14036379941481!3d25.907788681148624!2m3!1f357.26927939317244!2f20.870722720054623!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x88d9add4b4ac788f%3A0xe77469d09480fcdb!2sSunny%20Isles%20Beach!5e1!3m2!1sen!2sth!4v1642869952544!5m2!1sen!2sth"
                  width="100%"
                  height={'463px'}
                  style={{ border: 0, borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)' }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
              <div className="flex flex-row [&>div]:w-1/2 space-x-8 w-full">

                <div className="card2 p-[24px] flex flex-row space-x-5 items-center">
                  <FiPhoneCall className="text-orange-500 text-5xl" />
                  <div className="flex flex-col">
                    <h2 className="text-xl font-semibold text-gray-800">010-020-0340</h2>
                    <span className="text-gray-500">Phone Number</span>
                  </div>
                </div>

                <div className="card2 p-[24px] flex flex-row space-x-5 items-center">
                  <TfiEmail className="text-orange-500 text-5xl" />
                  <div className="flex flex-col">
                    <h2 className="text-xl font-semibold text-gray-800">info@villa.co</h2>
                    <span className="text-gray-500">Business Email</span>
                  </div>
                </div>

              </div>
            </div>
            <form onSubmit={handleForm} className="card2 p-[28px] col-span-7 lg:col-span-5 flex flex-col space-y-8 items-center justify-center"  ref={elementRef}>
              {
                !isSend ? (
                  <>
                  <Line span="Full Name" place="Your Name..." name="fullName" />
                  <Line span="Email Address" place="Your E-mail..." name="email" />
                  <Line span="Subject" place="Subject..." name="subject" />

                  <div className="flex flex-col space-y-2">
                    <span className="text-gray-900">
                      Message
                    </span>
                    <textarea name="message" className="bg-gray-100 py-2 px-4 rounded-3xl text-gray-700 h-[120px]" placeholder="Your Message..." />
                  </div>

                  <div>
                    <button type="submit" className="bg-gray-900 text-white text-lg px-5 py-2 rounded-full font-semibold">Send Message</button>
                  </div>
                  </>
                ) : (
                  <div className="text-[35px] text-gray-900 font-semibold flex flex-col">
                    <span>
                      We received the message,<br></br> our representative will get back to you soon
                    </span>
                  </div>
                )
              }


            </form>
          </div>
        </div>

      </div>

      <div className="bg-gray-800 h-20 flex items-center justify-center">
        <span className="text-white">Copyright © 2024 ClairWoods. Ltd. All rights reserved.</span>
      </div>

    </div>
  );
}
export default App;

const Line = ({span, place, name}) => {

  return (
    <div className="flex flex-col space-y-2">
      <span className="text-gray-900">
        {span}
      </span>
      <input name={name} className="bg-gray-100 py-2 px-4 rounded-full text-gray-700" type={span === 'Email Address' ? 'email' : 'text'} placeholder={place} required />
    </div>
  )
}





// https://templatemo.com/live/templatemo_591_villa_agency